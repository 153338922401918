const GenericWordpressToObject = (object => {
  return {
    title: object.node.title,
    titleDangerous: true,
    description: object.node.content,
    descriptionDangerous: true,
    excerpt: object.node.excerpt,
    slug: object.node.slug,
    image: object.node.featured_media
      ? object.node.featured_media.localFile.childImageSharp.fluid
      : null
  }
})

export {
  GenericWordpressToObject
}