import React from 'react'
import './FAQ.scss'
import HeroBanner from '../../components/HeroBanner'
import Layout from '../../components/Layout'
import { graphql } from 'gatsby'
import { GenericWordpressToObject } from '../../util/WordpressUtil'
import Collapsible from 'react-collapsible'

const FAQ = ({ data }) => {
  const items = data.allWordpressWpFaq.edges.map(item => {
    const converted = GenericWordpressToObject(item)
    return (
      <Collapsible trigger={<span dangerouslySetInnerHTML={{ __html: converted.title }} />} key={item.node.id}>
        <div dangerouslySetInnerHTML={{ __html: converted.description }}></div>
      </Collapsible>
    )
  })

  return (
    <Layout>
      <div className="FAQ">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="FAQs"
          caption="Answers to our most frequently asked questions."
        />
        <div className="container content">{items}</div>
      </div>
    </Layout>
  )
}

export default FAQ

export const query = graphql`
  query {
    allWordpressWpFaq(sort: {order: ASC, fields: order}) {
      edges {
        node {
          id
          content
          title
        }
      }
    }
    file(url: { eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
